import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const buttonDefaultVariants = {
  "variant": "solid",
  "color": "neutral",
  "size": "md",
  "roundness": "medium",
  "backdropBlur": false,
  "full": false,
  "autoHeight": false,
  "align": "center"
}
const buttonCompoundVariants = [
  {
    "size": "xs",
    "roundness": "none",
    "variant": [
      "solid",
      "soft",
      "outline",
      "ghost"
    ],
    "css": {
      "root": {
        "px": "calc(token(sizes.control.xs) * 0.45)",
        "borderRadius": "0"
      }
    }
  },
  {
    "size": "xs",
    "roundness": "medium",
    "variant": [
      "solid",
      "soft",
      "outline",
      "ghost"
    ],
    "css": {
      "root": {
        "px": "calc(token(sizes.control.xs) * 0.45)",
        "borderRadius": "token(radii.control.xs)"
      }
    }
  },
  {
    "size": "xs",
    "roundness": "full",
    "variant": [
      "solid",
      "soft",
      "outline",
      "ghost"
    ],
    "css": {
      "root": {
        "px": "calc(token(sizes.control.xs) * 0.45)",
        "borderRadius": "full"
      }
    }
  },
  {
    "size": "sm",
    "roundness": "none",
    "variant": [
      "solid",
      "soft",
      "outline",
      "ghost"
    ],
    "css": {
      "root": {
        "px": "calc(token(sizes.control.sm) * 0.45)",
        "borderRadius": "0"
      }
    }
  },
  {
    "size": "sm",
    "roundness": "medium",
    "variant": [
      "solid",
      "soft",
      "outline",
      "ghost"
    ],
    "css": {
      "root": {
        "px": "calc(token(sizes.control.sm) * 0.45)",
        "borderRadius": "token(radii.control.sm)"
      }
    }
  },
  {
    "size": "sm",
    "roundness": "full",
    "variant": [
      "solid",
      "soft",
      "outline",
      "ghost"
    ],
    "css": {
      "root": {
        "px": "calc(token(sizes.control.sm) * 0.45)",
        "borderRadius": "full"
      }
    }
  },
  {
    "size": "md",
    "roundness": "none",
    "variant": [
      "solid",
      "soft",
      "outline",
      "ghost"
    ],
    "css": {
      "root": {
        "px": "calc(token(sizes.control.md) * 0.45)",
        "borderRadius": "0"
      }
    }
  },
  {
    "size": "md",
    "roundness": "medium",
    "variant": [
      "solid",
      "soft",
      "outline",
      "ghost"
    ],
    "css": {
      "root": {
        "px": "calc(token(sizes.control.md) * 0.45)",
        "borderRadius": "token(radii.control.md)"
      }
    }
  },
  {
    "size": "md",
    "roundness": "full",
    "variant": [
      "solid",
      "soft",
      "outline",
      "ghost"
    ],
    "css": {
      "root": {
        "px": "calc(token(sizes.control.md) * 0.45)",
        "borderRadius": "full"
      }
    }
  },
  {
    "size": "lg",
    "roundness": "none",
    "variant": [
      "solid",
      "soft",
      "outline",
      "ghost"
    ],
    "css": {
      "root": {
        "px": "calc(token(sizes.control.lg) * 0.45)",
        "borderRadius": "0"
      }
    }
  },
  {
    "size": "lg",
    "roundness": "medium",
    "variant": [
      "solid",
      "soft",
      "outline",
      "ghost"
    ],
    "css": {
      "root": {
        "px": "calc(token(sizes.control.lg) * 0.45)",
        "borderRadius": "token(radii.control.lg)"
      }
    }
  },
  {
    "size": "lg",
    "roundness": "full",
    "variant": [
      "solid",
      "soft",
      "outline",
      "ghost"
    ],
    "css": {
      "root": {
        "px": "calc(token(sizes.control.lg) * 0.45)",
        "borderRadius": "full"
      }
    }
  },
  {
    "size": "xs",
    "variant": [
      "ghost",
      "outline",
      "soft",
      "solid"
    ],
    "hasStartSlot": true,
    "css": {
      "startSlot": {
        "mr": 1,
        "ml": -0.5
      }
    }
  },
  {
    "size": "xs",
    "variant": [
      "ghost",
      "outline",
      "soft",
      "solid"
    ],
    "hasEndSlot": true,
    "css": {
      "endSlot": {
        "ml": 1,
        "mr": -0.5
      }
    }
  },
  {
    "size": "sm",
    "variant": [
      "ghost",
      "outline",
      "soft",
      "solid"
    ],
    "hasStartSlot": true,
    "css": {
      "startSlot": {
        "mr": 1,
        "ml": -0.5
      }
    }
  },
  {
    "size": "sm",
    "variant": [
      "ghost",
      "outline",
      "soft",
      "solid"
    ],
    "hasEndSlot": true,
    "css": {
      "endSlot": {
        "ml": 1,
        "mr": -0.5
      }
    }
  },
  {
    "size": "md",
    "variant": [
      "ghost",
      "outline",
      "soft",
      "solid"
    ],
    "hasStartSlot": true,
    "css": {
      "startSlot": {
        "mr": 1.5,
        "ml": -1
      }
    }
  },
  {
    "size": "md",
    "variant": [
      "ghost",
      "outline",
      "soft",
      "solid"
    ],
    "hasEndSlot": true,
    "css": {
      "endSlot": {
        "ml": 1.5,
        "mr": -1
      }
    }
  },
  {
    "size": "lg",
    "variant": [
      "ghost",
      "outline",
      "soft",
      "solid"
    ],
    "hasStartSlot": true,
    "css": {
      "startSlot": {
        "mr": 2,
        "ml": -1.5
      }
    }
  },
  {
    "size": "lg",
    "variant": [
      "ghost",
      "outline",
      "soft",
      "solid"
    ],
    "hasEndSlot": true,
    "css": {
      "endSlot": {
        "ml": 2,
        "mr": -1.5
      }
    }
  },
  {
    "size": "xs",
    "variant": "basic",
    "hasStartSlot": true,
    "css": {
      "startSlot": {
        "mr": 1
      }
    }
  },
  {
    "size": "xs",
    "variant": "basic",
    "hasEndSlot": true,
    "css": {
      "endSlot": {
        "ml": 1
      }
    }
  },
  {
    "size": "sm",
    "variant": "basic",
    "hasStartSlot": true,
    "css": {
      "startSlot": {
        "mr": 2
      }
    }
  },
  {
    "size": "sm",
    "variant": "basic",
    "hasEndSlot": true,
    "css": {
      "endSlot": {
        "ml": 2
      }
    }
  },
  {
    "size": "md",
    "variant": "basic",
    "hasStartSlot": true,
    "css": {
      "startSlot": {
        "mr": 2.5
      }
    }
  },
  {
    "size": "md",
    "variant": "basic",
    "hasEndSlot": true,
    "css": {
      "endSlot": {
        "ml": 2.5
      }
    }
  },
  {
    "size": "lg",
    "variant": "basic",
    "hasStartSlot": true,
    "css": {
      "startSlot": {
        "mr": 3
      }
    }
  },
  {
    "size": "lg",
    "variant": "basic",
    "hasEndSlot": true,
    "css": {
      "endSlot": {
        "ml": 3
      }
    }
  },
  {
    "hasStartSlot": false,
    "hasEndSlot": false,
    "css": {
      "display": "none"
    }
  },
  {
    "align": "start",
    "css": {
      "startSlot": {
        "flex": 0
      }
    }
  },
  {
    "align": "end",
    "css": {
      "endSlot": {
        "flex": 0
      }
    }
  },
  {
    "iconOnly": true,
    "hasStartSlot": true,
    "css": {
      "startSlot": {
        "flex": 0,
        "mr": 0,
        "ml": 0
      },
      "endSlot": {
        "flex": 0
      },
      "root": {
        "px": 0,
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center"
      }
    }
  },
  {
    "iconOnly": true,
    "hasEndSlot": true,
    "css": {
      "startSlot": {
        "flex": 0
      },
      "endSlot": {
        "flex": 0,
        "ml": 0,
        "mr": 0
      },
      "root": {
        "px": 0,
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center"
      }
    }
  },
  {
    "size": "xs",
    "autoHeight": true,
    "css": {
      "height": "auto",
      "py": "calc(token(sizes.control.xs) * 0.4)"
    }
  },
  {
    "size": "sm",
    "autoHeight": true,
    "css": {
      "height": "auto",
      "py": "calc(token(sizes.control.sm) * 0.4)"
    }
  },
  {
    "size": "md",
    "autoHeight": true,
    "css": {
      "height": "auto",
      "py": "calc(token(sizes.control.md) * 0.4)"
    }
  },
  {
    "size": "lg",
    "autoHeight": true,
    "css": {
      "height": "auto",
      "py": "calc(token(sizes.control.lg) * 0.4)"
    }
  },
  {
    "size": "lg",
    "autoHeight": true,
    "css": {
      "height": "auto",
      "py": "calc(token(sizes.control.lg) * 0.4)"
    }
  }
]

const buttonSlotNames = [
  [
    "root",
    "button__root"
  ],
  [
    "startSlot",
    "button__startSlot"
  ],
  [
    "endSlot",
    "button__endSlot"
  ]
]
const buttonSlotFns = /* @__PURE__ */ buttonSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, buttonDefaultVariants, getSlotCompoundVariant(buttonCompoundVariants, slotName))])

const buttonFn = memo((props = {}) => {
  return Object.fromEntries(buttonSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const buttonVariantKeys = [
  "startSlotAlign",
  "endSlotAlign",
  "color",
  "variant",
  "size",
  "align",
  "roundness",
  "backdropBlur",
  "full",
  "hasStartSlot",
  "hasEndSlot",
  "iconOnly",
  "autoHeight",
  "focusRingDisabled"
]
const getVariantProps = (variants) => ({ ...buttonDefaultVariants, ...compact(variants) })

export const button = /* @__PURE__ */ Object.assign(buttonFn, {
  __recipe__: false,
  __name__: 'button',
  raw: (props) => props,
  variantKeys: buttonVariantKeys,
  variantMap: {
  "startSlotAlign": [
    "center",
    "side"
  ],
  "endSlotAlign": [
    "center",
    "side"
  ],
  "color": [
    "neutral",
    "positive",
    "warning",
    "critical",
    "accent"
  ],
  "variant": [
    "solid",
    "soft",
    "ghost",
    "outline",
    "basic"
  ],
  "size": [
    "xs",
    "sm",
    "md",
    "lg"
  ],
  "align": [
    "start",
    "center",
    "end"
  ],
  "roundness": [
    "none",
    "medium",
    "full"
  ],
  "backdropBlur": [
    "true"
  ],
  "full": [
    "true",
    "false"
  ],
  "hasStartSlot": [
    "true",
    "false"
  ],
  "hasEndSlot": [
    "true",
    "false"
  ],
  "iconOnly": [
    "true",
    "false"
  ],
  "autoHeight": [
    "true",
    "false"
  ],
  "focusRingDisabled": [
    "true",
    "false"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, buttonVariantKeys)
  },
  getVariantProps
})