import type { ReactNode } from "react";
import type { IconVariant } from "~/design/generated/recipes";
import { Icon, type IconName } from "./Icon";

export type ButtonSlotProps = {
  slot: IconName | ReactNode;
  size?: IconVariant["size"];
  color?: IconVariant["color"];
};

export function ButtonSlot({ slot, size, color }: ButtonSlotProps) {
  if (!slot) return null;

  if (typeof slot === "string") {
    return <Icon name={slot as IconName} size={size} color={color} />;
  }

  return slot;
}
